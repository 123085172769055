<template>
  <v-app
    id="login-home"
    dark
  >
      <vue-particles
      color="#04b604"
      linesColor= "#65b20a"
      >
      </vue-particles>
      <v-main>
      <password-recovery ref="pwdrecover" />
      <v-container fill-height fluid step="1">
        <v-layout align-center justify-center step="1">
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-card-title class="justify-center">
                <img src="/img/logotipo-300x115.png" />
              </v-card-title>
                <div class="text-h6 font-weight-regular text-center">
                  Sitio Bajo Mantenimiento. <br/> Contáctenos para obtener más información.
                </div>
              <v-card-text>
              </v-card-text>
              <v-divider class="mt-3" />
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {};
//<v-app id="login-home" v-bind:style="{ backgroundColor: 'gray'}">
</script>

<style>
#particles-js { 
  /*position: absolute;
  background-size: cover;
  top: 0; 
  bottom: 0; 
  left: 0;
  right: 0; 
  overflow-y: hidden; 
  z-index: 0;*/
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
</style>